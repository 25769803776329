export let propList = [
  { prop: 'id',label: 'ID', align: 'center', width: '80', webId: 1, fixed: 'left', },
  { prop: 'state', label: '类型', minWidth: '200', align: 'center', showOverflowTooltip: true, slotName: 'stateSlot', webId: 2 },
  { prop: 'comment', label: '备注', minWidth: '200', align: 'center', showOverflowTooltip: true, copy: true, webId: 3 },
  { prop: 'content', label: '留言内容', minWidth: '200', align: 'center', showOverflowTooltip: true, copy: true, webId: 4 },
  { prop: 'name', label: '姓名', minWidth: '150', align: 'center', showOverflowTooltip: true, copy: true, webId: 5 },
  { prop: 'phone', label: '电话', minWidth: '200', align: 'center', showOverflowTooltip: true, copy: true, webId: 6 },
  { prop: 'email', label: '邮箱', minWidth: '200', align: 'center', showOverflowTooltip: true, copy: true, webId: 7 },
  { prop: 'address', label: '地址', minWidth: '200', align: 'center', showOverflowTooltip: true, copy: true, webId: 8 },
  { prop: 'create_time', label: '创建时间', minWidth: '200', align: 'center', dateFormat: 'dateTime', showOverflowTooltip: true, webId: 9 },
  { prop: 'update_time', label: '更新时间', minWidth: '200', align: 'center', dateFormat: 'dateTime', showOverflowTooltip: true, webId: 10 },
]
export let operationProps = {
  isShow: true, //设置后面的选择选择条例出不出现
  checkedCities: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], //配置默认出现的条例对应上方的webId
  checkeList: [
    //配置有哪些条咧id对应上方的webId
    { id: 1, label: 'ID' },
    { id: 2, label: '类型' },
    { id: 3, label: '备注' },
    { id: 4, label: '留言内容' },
    { id: 5, label: '姓名' },
    { id: 6, label: '电话' },
    { id: 7, label: '邮箱' },
    { id: 8, label: '地址' },
    { id: 9, label: '创建时间' },
    { id: 10, label: '更新时间' },
  ],
  width: 150, //配置选择栏的样式 宽带是多少
  align: 'center' //字体居中
}
export let otherProps = {
  showSelectColumn: false, //是否显示多选框
  showFooter: true //显示底部分页
}
